<template>
  <TransitionRoot :show="isShowUploader" as="template">
    <Dialog as="div" @close="onCloseDialog()" class="relative z-[9999]">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[
                !dropzoneActive
                  ? 'border-gray-600 bg-gray-100 hover:border-gray-300 dark:bg-gray-800'
                  : 'border-gray-300 bg-blue-100 dark:bg-blue-950',
              ]"
              class="w-full max-w-3xl transform overflow-hidden rounded-lg p-6 align-middle shadow-xl transition-all"
            >
              <div class="mb-6 flex items-center justify-between">
                <DialogTitle as="h3" class="text-start text-lg font-medium leading-6">
                  {{ $t('Upload files dialog') }}
                </DialogTitle>
                <button @click="onCloseDialog()" class="text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Close</span>
                  <SvgIcon :path="mdiClose" size="lg" />
                </button>
              </div>

              <div
                :class="[
                  'relative w-full overflow-hidden rounded-lg border-2 border-dashed transition',
                  isNewFilesAdding && 'pointer-events-none opacity-70',
                ]"
              >
                <!-- dropbox -->
                <div
                  class="block cursor-pointer px-6 py-10 text-center"
                  @click="selectDialogActive = !selectDialogActive"
                  ref="dropboxRef"
                >
                  <SvgIcon :path="mdiImageFilterDrama" size="3xl" class="text-gray-500" />
                  <div class="mt-2 text-xl">{{ $t('uploader.dropzone.title') }}</div>
                  <div class="mt-2 text-base text-gray-500">
                    {{
                      $t('uploader.dropzone.sizeLimit', {
                        bytes: formatBytes(uploaderConfig.maxBytes),
                      })
                    }}
                  </div>
                  <div class="mt-2 text-base text-gray-500">
                    {{ $t('uploader.dropzone.countLimit', { count: moreFilesCount }) }}<br />
                    <template v-if="currentListByteSize > 0">
                      {{
                        $t('uploader.dropzone.currentSize', {
                          bytes: formatBytes(uploaderConfig.maxBytes - currentListByteSize),
                        })
                      }}
                    </template>
                  </div>

                  <!-- files/dir dialog -->
                  <div
                    :class="[
                      'pointer-events-none absolute bottom-8 left-1/2 flex -translate-x-1/2 flex-col bg-gray-100 py-2 opacity-0 shadow-lg transition dark:bg-gray-700',
                      selectDialogActive && 'pointer-events-auto opacity-100',
                    ]"
                    @click.stop
                  >
                    <label
                      for="uploaderFile"
                      class="inline-flex cursor-pointer items-center px-4 py-2 transition hover:bg-gray-200 dark:hover:bg-gray-600"
                    >
                      <SvgIcon :path="mdiFileDocumentPlusOutline" class="mr-2" />
                      <span class="text-base">Upload files</span>
                    </label>

                    <label
                      for="uploaderDirectory"
                      class="inline-flex cursor-pointer items-center px-4 py-2 transition hover:bg-gray-200 dark:hover:bg-gray-600"
                    >
                      <SvgIcon :path="mdiFolderPlusOutline" class="mr-2" />
                      <span class="text-base">Upload folders</span>
                    </label>
                  </div>
                </div>

                <!-- hidden input (files) -->
                <input
                  id="uploaderFile"
                  ref="uploaderFileDom"
                  class="pointer-events-none absolute bottom-0 left-0 h-px w-px opacity-0"
                  type="file"
                  :multiple="true"
                  @change="handleFileSelect"
                />

                <!-- hidden input (directory) -->
                <input
                  id="uploaderDirectory"
                  ref="uploaderDirectoryDom"
                  class="pointer-events-none absolute bottom-0 left-0 h-px w-px opacity-0"
                  type="file"
                  :multiple="true"
                  @change="handleDirectorySelect"
                  directory
                  webkitdirectory
                  mozdirectory
                />
              </div>

              <!-- filelist -->
              <PreviewFileList @close-selector="onCloseDialog(true)" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>

  <TransitionRoot :show="isOpenConfirm" as="template">
    <Dialog as="div" @close="isOpenConfirm = false" class="relative z-[99999]">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              :class="[
                !dropzoneActive
                  ? 'border-gray-600 bg-gray-100 hover:border-gray-300 dark:bg-gray-800'
                  : 'border-gray-300 bg-blue-100 dark:bg-blue-950',
              ]"
              class="w-full max-w-lg transform overflow-hidden rounded-lg p-6 align-middle shadow-xl transition-all"
            >
              <div class="mb-6 flex items-center justify-between">
                <DialogTitle as="h3" class="text-start text-lg font-medium leading-6 text-red-500">
                  {{ $t('Confirm to close uploader') }}
                </DialogTitle>
              </div>

              <p class="leading-relaxed">
                {{
                  $t(
                    'Warning: Closing the uploader now will discard your selected files. Are you sure you want to proceed?',
                  )
                }}
              </p>

              <div class="mt-4 space-x-2 text-end">
                <UiButton class="w-fit" size="small" @click="isOpenConfirm = false">
                  {{ $t('Cancel') }}
                </UiButton>
                <UiButton
                  type="submit"
                  class="w-fit"
                  size="small"
                  theme="danger"
                  @click="(isOpenConfirm = false), onCloseDialog(true)"
                >
                  {{ $t('auth.confirm.form.action') }}
                </UiButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { uploaderConfig } from '@/core/config/uploader'
import { IUploadMimeInfo } from '@/core/interface'
import { formatBytes } from '@/core/utils/File'
import { FileInfo } from '@/core/utils/storage.idbdatabase'
import UiButton from '@c/Ui/Button.vue'
import SvgIcon from '@c/Ui/SvgIcon.vue'
import PreviewFileList from '@c/Uploader/PreviewFileList.vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
  mdiClose,
  mdiFileDocumentPlusOutline,
  mdiFolderPlusOutline,
  mdiImageFilterDrama,
} from '@mdi/js'
import { onClickOutside } from '@vueuse/core'
import { nanoid } from 'nanoid'

const uiStore = useUiStore()
const uploadStore = useUploadStoreV2()
const { isNewFilesAdding, isShowUploader, pendingFiles } = storeToRefs(uploadStore)

const isOpenConfirm = ref(false)
function onCloseDialog(force = false) {
  if (!pendingFiles.value.length || force) {
    uploadStore.setIsShowUploader(false)
  } else {
    isOpenConfirm.value = true
  }
}

const { t } = useI18n()
const route = useRoute()

const dropboxRef = ref<HTMLDivElement | null>(null)
const uploaderFileDom = ref<HTMLInputElement | null>(null)
const uploaderDirectoryDom = ref<HTMLInputElement | null>(null)
const dropzoneActive = ref(false)
const selectDialogActive = ref(false)

onClickOutside(dropboxRef, () => (selectDialogActive.value = false))

const moreFilesCount = computed(() => {
  const diff = uploaderConfig.maxFilesTotal - pendingFiles.value.length
  if (diff <= 0) return 0
  return diff
})

const currentListByteSize = computed(() => {
  return pendingFiles.value.reduce((acc, x) => {
    acc += x.file?.size || 0
    return acc
  }, 0)
})

// select files
const handleFileSelect = async (e: Event) => {
  const target = e.target as HTMLInputElement
  if (!target.files) return

  handleMultipleUpload(target.files)

  if (uploaderFileDom.value) {
    uploaderFileDom.value.value = ''
  }
}

// select directories
const handleDirectorySelect = (e: Event) => {
  const target = e.target as HTMLInputElement
  if (!target.files) return

  handleMultipleUpload(target.files)

  if (uploaderDirectoryDom.value) {
    uploaderDirectoryDom.value.value = ''
  }
}

// return files which will be asked for uploadSession
// duplicates filtering and upload limits
// validation of files is done separately, files will be added to list, but not uploaded
const filterFilesToProcess = (files: File[]) => {
  // disallow duplicates (even in previously uploaded)
  let newFileList = files.filter((file) => {
    const isDuplicate = false // allow duplicates
    const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
    const isImageLike = uploaderConfig.allowedImgLike.includes(
      fileExt?.toLocaleLowerCase(),
    ) as boolean

    // remove special system files and non-image files
    if (['.DS_Store'].includes(file.name) || !isImageLike) return false

    if (isDuplicate) {
      uiStore.addToast({
        type: 'error',
        message: t('uploader.validation.duplicate', { name: file.name }),
      })
    }

    return !isDuplicate ? file : false
  })

  // limit maxium files
  const totalFiles = pendingFiles.value.length + newFileList.length
  if (totalFiles > uploaderConfig.maxFilesTotal) {
    newFileList = newFileList.slice(0, uploaderConfig.maxFilesTotal - pendingFiles.value.length)
    uiStore.addToast({
      type: 'error',
      message: t('uploader.validation.maxfiles', { max: uploaderConfig.maxFilesTotal }),
    })
  }

  // limit total size
  const newListBytes = newFileList.reduce((acc, x) => {
    acc += x.size || 0
    return acc
  }, 0)
  const newbytesTotal = currentListByteSize.value + newListBytes
  if (newbytesTotal > uploaderConfig.maxBytes) {
    t('uploader.validation.maxTotalSize', {
      max: formatBytes(uploaderConfig.maxBytes),
    })

    // TODO truncate based on size
    return []
  }

  return newFileList
}

const handleMultipleUpload = async (files: FileList | File[]) => {
  if (isNewFilesAdding.value) return // wait for previous session to resolve

  const filesArr = Array.isArray(files) ? files : Array.from(files)

  const newFileList = uploaderConfig.useFrontendValidation
    ? filterFilesToProcess(filesArr)
    : filesArr
  if (!newFileList.length) return

  uploadStore.setIsNewFilesAdding(true)

  const filesToProcess = newFileList.map((file) => processFile(file))
  let newUploads = await Promise.all(filesToProcess)

  uploadStore.push(...newUploads)
  uploadStore.setIsNewFilesAdding(false)
}

const getFileMimeInfo = (file: File) => {
  const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)

  const isImageMime = uploaderConfig.allowedMimes.includes(file.type) as boolean
  const isArchive = uploaderConfig.allowedArchives.includes(fileExt?.toLocaleLowerCase()) as boolean
  const isImageLike = uploaderConfig.allowedImgLike.includes(
    fileExt?.toLocaleLowerCase(),
  ) as boolean

  return {
    ext: fileExt,
    isImage: isImageMime,
    isArchive: isArchive,
    isImageLike: isImageLike,
    isReadable: isImageMime || ['fit', 'fits'].includes(fileExt),
  }
}

// return error on single file if any
const validateFile = (file: File, mimeInfo: IUploadMimeInfo) => {
  if (!mimeInfo.isImage && !mimeInfo.isArchive && !mimeInfo.isImageLike) {
    return t('uploader.validation.badExt', { ext: mimeInfo.ext })
  }

  if (file.size > uploaderConfig.maxBytes) {
    return t('uploader.validation.badMaxSize', {
      size: formatBytes(uploaderConfig.maxBytes),
    })
  }

  if (file.size < uploaderConfig.minBytes) {
    return t('uploader.validation.badMinSize', {
      size: formatBytes(uploaderConfig.minBytes),
    })
  }

  return null
}

const processFile = async (file: File) => {
  const resultFile: FileInfo = {
    fuid: nanoid(16),
    name: file.name,
    folder: file.webkitRelativePath.split(file.name).shift() ?? '',
    error: undefined,
    type: 'image',
    created_at: Date.now().valueOf(),
    file,
    status: FileStatus.Pending,
  }

  const mimeInfo = getFileMimeInfo(file)
  const validationError = uploaderConfig.useFrontendValidation && validateFile(file, mimeInfo)
  if (validationError) {
    resultFile.error = validationError
  }

  // consider default mime as image, set other if not image
  if (mimeInfo.isArchive) {
    resultFile.type = 'archive'
  } else if (mimeInfo.isImageLike) {
    resultFile.type = 'imagelike'
  }

  if (resultFile.type === 'image' && file.size <= uploaderConfig.readerMaxSize) {
    resultFile.preview_url = window.URL.createObjectURL(file)
  }

  return resultFile
}

// drag & drop / paste
const onDropEnter = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()

  dropzoneActive.value = true
}

const onDropLeave = (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()

  dropzoneActive.value = false
}

const handleFileDrop = async (e: DragEvent) => {
  e.preventDefault()
  e.stopPropagation()
  // const files = e.dataTransfer?.files
  const items = e.dataTransfer?.items!
  const files = await getAllFilesFromDataTransfer(items)

  dropzoneActive.value = false

  if (files) {
    handleMultipleUpload(files)
  }
}

const handleFilePaste = async (e: ClipboardEvent) => {
  if (route.name !== 'home') return

  const data = e.clipboardData
  const items = data?.items!
  if (items) {
    e.preventDefault()
    e.stopPropagation()

    const files = await getAllFilesFromDataTransfer(items)
    handleMultipleUpload(files)
  }
}

// remove errored files on initial loads
const dbCleanUp = async () => {
  pendingFiles.value.forEach((u) => {
    if (u.error) {
      uploadStore.remove(u.fuid)
    }
  })
}

const bindEvents = () => {
  window.addEventListener('dragenter', onDropEnter, false)
  window.addEventListener('dragover', onDropEnter, false)
  window.addEventListener('dragleave', onDropLeave, false)
  window.addEventListener('drop', handleFileDrop, false)
  document.addEventListener('paste', handleFilePaste, false)
}
const unbindEvents = () => {
  window.removeEventListener('dragenter', onDropEnter, false)
  window.removeEventListener('dragover', onDropEnter, false)
  window.removeEventListener('dragleave', onDropLeave, false)
  window.removeEventListener('drop', handleFileDrop, false)
  document.removeEventListener('paste', handleFilePaste, false)
}
onMounted(() => {
  bindEvents()
  dbCleanUp()
})
onBeforeUnmount(() => {
  unbindEvents()
})
watch(isShowUploader, (newVal) => {
  if (newVal) {
    bindEvents()
  } else {
    unbindEvents()
  }
})
</script>
