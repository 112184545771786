import { CHUNK_SIZE } from '../constant'
import { ChunkFile, IImageData } from '../interface'

export const blobThroughReader = (
  blob: Blob,
): Promise<{
  width: number
  height: number
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = async () => {
      const px = await getImagePxSize(reader.result as string)
      px ? resolve(px) : reject()
    }

    reader.onerror = reject

    reader.readAsDataURL(blob)
  })
}

export const getImagePxSize = async (
  blobUrlOrDataUrl: string,
): Promise<{
  width: number
  height: number
}> => {
  return new Promise((resolve, reject) => {
    const imgNode = document.createElement('img')

    imgNode.onload = () => {
      resolve({
        width: imgNode.naturalWidth,
        height: imgNode.naturalHeight,
      })
      imgNode.remove()
    }

    imgNode.onerror = () => {
      reject()
      imgNode.remove()
    }

    imgNode.src = blobUrlOrDataUrl
  })
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['byte', 'Kb', 'Mb', 'Gb']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const bytesToMegaBytes = (bytes: number) => {
  if (bytes === 0) return null

  const k = 1024

  return Math.round((bytes / (k * k)) * 1e2) / 1e2
}

export const blobToUint8Array = async (blob: Blob | File): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    try {
      blob.arrayBuffer().then((buffer) => {
        resolve(new Uint8Array(buffer))
      })
    } catch {
      reject()
    }
  })
}

export const loadUrlToBlob = (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('get', url)
    xhr.responseType = 'blob'

    xhr.onload = async function (e) {
      resolve(xhr.response)
    }

    xhr.onerror = () => {
      const cacheBuster = `?_=${new Date().getTime()}`
      const retryXhr = new XMLHttpRequest()
      retryXhr.open('get', url + cacheBuster)
      retryXhr.responseType = 'blob'

      retryXhr.onload = async function (e) {
        resolve(retryXhr.response)
      }

      retryXhr.onerror = reject
      retryXhr.send()
    }
    xhr.send()
  })
}

export const loadUrlToUint8Array = (url: string): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    loadUrlToBlob(url)
      .then(async (blob) => {
        resolve(await blobToUint8Array(blob))
      })
      .catch(reject)
  })
}

function canasToBlob(
  canvas: HTMLCanvasElement,
  type = 'image/jpeg',
  quality = 1,
): Promise<Blob | null> {
  return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), type, quality))
}

export const imageNodeToUint8Array = async ({
  source,
}: {
  source: IImageData
}): Promise<Uint8Array> => {
  return new Promise((resolve, reject) => {
    const imageNode = new Image()
    imageNode.crossOrigin = 'anonymous'

    const canvas = document.createElement('canvas')
    canvas.width = source.width
    canvas.height = source.height
    const context = canvas.getContext('2d')!

    imageNode.src = source.url
    document.documentElement.appendChild(canvas)

    imageNode.onload = async () => {
      context.drawImage(imageNode, 0, 0, source.width, source.height)

      const blob = await canasToBlob(context.canvas, source.mimeType)
      resolve(await blobToUint8Array(blob!))
    }
    imageNode.onerror = reject
  })
}

export const getFileSHA = async (file: File) => {
  // const DEV_PERF = performance.now()

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async function (event) {
      const hashBuffer = await crypto.subtle.digest('SHA-1', event.target?.result as ArrayBuffer)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
      // console.log('getFileSHA', (performance.now() - DEV_PERF).toFixed(2) + ' ms')

      resolve(hashHex)
    }
    reader.readAsArrayBuffer(file)
  })
}

interface FileSize {
  value: number
  unit: 'MB' | 'GB' | 'TB' | 'KB'
}
export const getFileSizeFormat = (fileSizeKB: number): FileSize => {
  let size = fileSizeKB
  let unit: 'MB' | 'GB' | 'TB' | 'KB' = 'KB'

  if (size >= 1024 * 1024) {
    size = size / (1024 * 1024)
    unit = 'TB'
  } else if (size >= 1024) {
    size = size / 1024
    if (size >= 1024) {
      size = size / 1024
      unit = 'GB'
    } else {
      unit = 'MB'
    }
  }

  return { value: Math.round(size * 10) / 10, unit }
}
