/**
 * Core URQL client configuration file
 * This file sets up the GraphQL client with authentication, subscriptions, and error handling
 */

import { GetGraphqlSubscriptionInfo } from '@/graphql/generated'
import { authExchange } from '@urql/exchange-auth'
import { retryExchange } from '@urql/exchange-retry'
import {
  cacheExchange,
  Client,
  errorExchange,
  fetchExchange,
  subscriptionExchange,
} from '@urql/vue'
import { createClient as createWSClient } from 'graphql-ws'
import { useSessionStore } from './store/sessionStore'
import { get } from 'lodash'
import { debounce } from 'lodash'

/**
 * Retrieves the JWT access token from the session store
 * @returns {string | undefined} The JWT access token or undefined if not available
 */
function getToken(): string | undefined {
  try {
    const sessionStore = useSessionStore()
    const { JWTtokens } = storeToRefs(sessionStore)
    const token = JWTtokens.value?.access
    return token
  } catch (error) {
    // console.error('Error getting token', error)
    return undefined
  }
}

/**
 * Constructs the WebSocket subscription URL with authentication headers
 * @returns {Promise<string>} The fully constructed WebSocket URL
 */
async function getSubscriptionUrl(): Promise<string> {
  const token = getToken()
  if (!token) {
    return ''
  }

  const result = await urqlClient.value
    .query<GetGraphqlSubscriptionInfo, {}>(GetGraphqlSubscriptionInfoDocument, {})
    .toPromise()

  wsUri.value = result.data?.getGraphqlSubscriptionInfo?.uri || ''
  wsStream.value = result.data?.getGraphqlSubscriptionInfo?.stream || ''
  wsHost.value = wsUri.value
    .replace('wss://', '')
    .replace('/graphql', '')
    .replace('-realtime-', '-')

  const header = btoa(JSON.stringify({ host: wsHost.value, Authorization: getToken() }))
  const url = `${wsUri.value}?header=${header}&payload=e30=`
  return url
}

// State management for WebSocket connection
const urqlClientSubscriptionError = ref('')
const isUrqlClientReadySubscription = ref(false)
const appsyncClientUrl = import.meta.env.VITE_APPSYNC_CLIENT_URL
const wsClient = ref<ReturnType<typeof createWSClient> | null>(null)
const wsUri = ref('')
const wsStream = ref('')
const wsHost = ref('')

// Add debounce time constants
const RECONNECT_DELAY = 5000
const VISIBILITY_DEBOUNCE = 1000

// Add debounced version of createNewUrqlClient
const debouncedCreateNewUrqlClient = debounce(async () => {
  console.log('Debounced createNewUrqlClient')
  cleanup()
  await initWs()
  urqlClient.value = init()
}, RECONNECT_DELAY)

/**
 * Initializes the WebSocket connection for GraphQL subscriptions
 * Handles connection setup, retry logic, and protocol adaptations for AWS AppSync
 */
async function initWs(): Promise<void> {
  // Prevent multiple simultaneous connection attempts
  if (wsClient.value) {
    console.log('WebSocket connection already exists')
    return
  }

  try {
    const url = await getSubscriptionUrl()
    if (!url) {
      return
    }

    wsClient.value = createWSClient({
      url,
      // Custom WebSocket implementation with protocol specification
      webSocketImpl: class extends WebSocket {
        constructor(url: string) {
          super(url, ['graphql-ws'])
        }
      },
      // Exponential backoff retry strategy
      retryAttempts: 5,
      shouldRetry: () => true,
      retryWait: (retries) =>
        new Promise((resolve) => setTimeout(resolve, Math.min(1000 * Math.pow(2, retries), 30000))),

      // Protocol adaptation for AWS AppSync compatibility
      jsonMessageReplacer: (key, value) => {
        if (key === 'type' && value === 'subscribe') {
          return 'start'
        }

        return value
      },
      jsonMessageReviver: (key, value) => {
        if (key === 'type') {
          if (value === 'start') {
            return 'subscribe'
          }

          if (value === 'start_ack' || value === 'ka') {
            return 'connection_ack'
          }

          if (value === 'data') {
            return 'next'
          }
        }

        if (key === 'errors' && value) {
          const errorMessage = get(value, '[0].message', 'Unknown error occurred')
          urqlClientSubscriptionError.value = errorMessage
        }

        return value
      },

      // WebSocket lifecycle event handlers
      on: {
        error: (error) => {
          console.error('WebSocket error:', error)
          isUrqlClientReadySubscription.value = false
          debouncedCreateNewUrqlClient()
        },
        closed: () => {
          console.log('WebSocket closed')
          isUrqlClientReadySubscription.value = false
          debouncedCreateNewUrqlClient()
        },
      },
    })
    isUrqlClientReadySubscription.value = true
  } catch (error) {
    console.error('WebSocket initialization error:', error)
    urqlClientSubscriptionError.value = 'Failed to establish WebSocket connection'
    debouncedCreateNewUrqlClient()
  }
}

/**
 * Cleans up WebSocket resources and resets connection state
 */
function cleanup() {
  if (wsClient.value) {
    wsClient.value.dispose()
    wsClient.value = null
  }
  isUrqlClientReadySubscription.value = false
}

/**
 * Initializes the URQL client with all necessary exchanges and configuration
 * @returns {Client} Configured URQL client instance
 */
function init(): Client {
  return new Client({
    url: appsyncClientUrl,
    fetchSubscriptions: false,
    exchanges: [
      cacheExchange,
      // Retry logic for failed requests
      retryExchange({
        initialDelayMs: 1000,
        maxDelayMs: 15000,
        randomDelay: true,
        maxNumberAttempts: 2,
        retryIf: (error) => {
          return !!(error.graphQLErrors.length > 0 || error.networkError)
        },
      }),
      // Authentication exchange for handling tokens and auth errors
      authExchange(async (utils) => {
        return {
          // Adds authentication headers to operations
          addAuthToOperation(operation) {
            const token = getToken()

            return utils.appendHeaders(operation, {
              Authorization:
                import.meta.env.VITE_FORCE_AUTH_CUSTOM === 'y' ? `custom-${token}` : token || '',
            })
          },
          // Determines if an error is auth-related
          didAuthError(error, _operation) {
            console.log('error', { error, _operation })
            const forbidenMessage = error.graphQLErrors.some((e) => e.message.includes('expired'))

            const forbidenStatus = error.response?.status === 401

            return forbidenMessage || forbidenStatus
          },
          // Handles token refresh
          async refreshAuth() {
            const sessionStore = useSessionStore()

            const sessionRes = await getRefreshCognitoSession()
            console.log('sessionRes', { sessionRes })
            if (!sessionRes) {
              sessionStore.logout({ autoRedirectToLogin: true })
            }
          },
        }
      }),
      // Global error handling
      errorExchange({
        onError: (error) => {
          console.log('errorExchange', { error })
          if (error.response && error.response?.status === 400) {
            router.push('/400')
          }
        },
      }),
      fetchExchange,
      // Subscription exchange configuration
      subscriptionExchange({
        forwardSubscription(request) {
          const input = {
            query: request.query || '',
            data: JSON.stringify({
              query: request.query || '',
              variables: request.variables,
            }),
            extensions: {
              ...request.extensions,
              authorization: {
                host: wsHost.value,
                Authorization: getToken(),
              },
            },
          }

          return {
            subscribe(sink) {
              if (!wsClient.value) {
                throw new Error('WebSocket client not initialized')
              }

              const unsubscribe = wsClient.value.subscribe(input, sink)

              return { unsubscribe }
            },
          }
        },
      }),
    ],
  })
}

// Client instance and management
const urqlClient = ref(init())

/**
 * Creates a new URQL client instance and reinitializes WebSocket connection
 * Used for reconnection and client refresh scenarios
 */
const createNewUrqlClient = async (): Promise<void> => {
  console.log('createNewUrqlClient')
  cleanup()
  await initWs()
  urqlClient.value = init()
}

// Initial client creation
createNewUrqlClient()

// Tab visibility handling for connection management
if (typeof window !== 'undefined') {
  const debouncedVisibilityHandler = debounce(() => {
    if (document.visibilityState === 'visible' && !isUrqlClientReadySubscription.value) {
      debouncedCreateNewUrqlClient()
    }
  }, VISIBILITY_DEBOUNCE)

  document.addEventListener('visibilitychange', debouncedVisibilityHandler)
}

// Exports
export {
  createNewUrqlClient,
  isUrqlClientReadySubscription,
  urqlClient,
  urqlClientSubscriptionError,
  wsStream,
}
