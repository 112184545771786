<template>
  <div v-if="show" class="setup-dialog-overlay">
    <div class="setup-dialog bg-white text-gray-900 dark:bg-gray-900 dark:text-white">
      <h2>Setting up your account...</h2>

      <div class="progress-container flex flex-1 items-center">
        <div v-show="!isComplete" class="relative size-40">
          <svg class="size-full -rotate-90" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
            <!-- Background Circle -->
            <circle
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current text-gray-200 dark:text-neutral-700"
              stroke-width="2"
            ></circle>
            <!-- Progress Circle -->
            <circle
              cx="18"
              cy="18"
              r="16"
              fill="none"
              class="stroke-current text-green-600 transition-all duration-500 dark:text-green-500"
              stroke-width="2"
              stroke-dasharray="100"
              :stroke-dashoffset="100 - progress"
              stroke-linecap="round"
            ></circle>
          </svg>
        </div>

        <!-- Show checkmark when complete -->
        <SvgIcon v-show="isComplete" :path="mdiCheck" class="!size-40 text-green-500" />
      </div>

      <Button
        v-if="isComplete"
        size="small"
        class="ml-auto !bg-green-500 hover:!bg-green-600 focus:!bg-green-700 active:!shadow-[0px_0px_0px_2px_rgb(22,163,74,1)]"
        @click="$emit('close')"
      >
        close
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { mdiCheck } from '@mdi/js'
import SvgIcon from '../Ui/SvgIcon.vue'
import Button from '../Ui/Button.vue'

const props = defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'setup-complete'): void
}>()

const progress = ref(0)
const isComplete = ref(false)
const interval = ref<NodeJS.Timeout>()

const { executeMutation: setupUserMutation } = useSetupUser()

const onSetupUser = async () => {
  // Start progress animation - move slower at first, then faster
  interval.value = setInterval(() => {
    // Increment by smaller amounts until 80%, then slow down
    const increment = progress.value < 80 ? 2 : 0.5
    progress.value = Math.min(progress.value + increment, 100)
  }, 100)

  let retries = 0
  const maxRetries = 3
  const retryDelay = 5000 // 5 seconds

  while (retries <= maxRetries) {
    try {
      const { data, error } = await setupUserMutation({})
      if (error || !data?.setupUser?.success) {
        throw error
      }

      setTimeout(() => {
        isComplete.value = true
      }, 600)
      break // Exit if successful
    } catch (error) {
      retries++
      if (retries === maxRetries) {
        $sentry.captureException(new Error('Setup failed after 3 retries'), { extra: { error } })
        break
      }
      // Wait 5 seconds before retrying
      await new Promise((resolve) => setTimeout(resolve, retryDelay))
    }
  }

  // Clean up after all retries are exhausted or on success
  clearInterval(interval.value)
  progress.value = 100
}

onMounted(onSetupUser)

// Clean up interval on component unmount
onUnmounted(() => {
  if (interval.value) {
    clearInterval(interval.value)
  }
})

watch(
  () => props.show,
  (newVal) => {
    document.body.classList.toggle('overflow-y-hidden', newVal)
  },
  {
    immediate: true,
  },
)
</script>

<style scoped>
.setup-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999;
}

.setup-dialog {
  background: white;
  border-radius: 8px;
  padding: 2rem 1rem;
  width: 400px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.progress-container {
  margin: 2rem 0;
  width: 150px;
  height: 150px;
  position: relative;
}

.spinner {
  /* width: 100%;
  height: 100%; */
  position: relative;
}

.spinner-circle {
  width: 100%;
  height: 100%;
  border: 8px solid #eee;
  border-top: 8px solid #4caf50;
  border-radius: 50%;
  transition: transform 0.1s linear;
}

.checkmark {
  font-size: 100px;
  color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.close-button {
  padding: 8px 24px;
  background: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.close-button:hover {
  background: #45a049;
}
</style>
