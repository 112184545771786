import { OnImageSetUpdate } from '@/graphql/generated'
import { SubscriptionHandlerArg } from '@urql/vue'

export const useGallerySubscriptionImageSetUpdate = (): void => {
  const galleryStore = useGalleryStore()

  const handleImageSetUpdate: SubscriptionHandlerArg<OnImageSetUpdate, void> = (_, response) => {
    if (!response.onImageSetUpdate?.imageset) return

    switch (response.onImageSetUpdate.type) {
      case 'deleted':
        galleryStore.removeImageSet(response.onImageSetUpdate.imageset.set_id)
        break
      case 'updated':
        galleryStore.updateImageSet(response.onImageSetUpdate.imageset)
        break
    }
  }
  const variables = computed(() => ({ stream: wsStream.value ?? '' }))
  useOnImageSetUpdate(
    {
      variables,
      pause: computed(() => !isUrqlClientReadySubscription.value || !wsStream.value),
    },
    handleImageSetUpdate,
  )
}
